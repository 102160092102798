<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('refund_request')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('refund_request')"
                              :isFilter="false"/>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <div class="mb-4">
                        <h6 class="text-uppercase mb-0">{{ $t('application_information') }}</h6>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('student_number')">
                        {{ user.student.student_number }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('name_surname')">
                        {{ user.name + ' ' + user.surname }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('nationality')">
                        {{ user.person.nationality_name }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('faculty')">
                        {{
                            user.active_student_program.faculty_name
                        }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('department')">
                        {{
                            user.active_student_program.program_name
                        }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('class')">
                        {{ $t('class_x', {class: user.active_student_program.class}) }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('national_id_passport_no')">
                        {{ user.person.national_id }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('email')">
                        {{ user.person.email }}
                    </b-form-group>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <b-form-group :label="$t('mobile_number')">
                        {{ user.person.mobile_tel }}
                    </b-form-group>
                </div>
            </div>
            <div class="mb-3 d-inline-block"></div>
            <div class="mb-4">
                <h6 class="text-uppercase mb-0">{{ $t('account_information') }}</h6>
            </div>
            <ValidationObserver ref="refundRequestForm">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-5-grid">
                        <ValidationProvider name="name_surname" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('name_surname')">
                                <b-form-input type="text"
                                              v-model="refundRequestForm.name_surname"
                                              v-uppercase
                                              :state="errors[0] ? false : null">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-5-grid">
                        <ValidationProvider name="bank_name" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('bank_name')">
                                <b-form-input type="text"
                                              v-model="refundRequestForm.bank_name"
                                              v-uppercase
                                              :state="errors[0] ? false : null">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-5-grid">
                        <ValidationProvider name="branch_name" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('branch_name')">
                                <b-form-input type="text"
                                              v-model="refundRequestForm.branch_name"
                                              v-uppercase
                                              :state="errors[0] ? false : null">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-5-grid">
                        <ValidationProvider name="iban" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('iban')">
                                <b-form-input type="text"
                                              v-model="refundRequestForm.iban"
                                              v-mask="['AA##-####-####-####-####-####-##']"
                                              :state="errors[0] ? false : null">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-5-grid">
                        <ValidationProvider name="currency" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('currency')">
                                <currency-selectbox v-model="refundRequestForm.currency"
                                                    :validate-error="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-5-grid">
                        <ValidationProvider name="reason_for_return" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('reason_for_return')">
                                <parameter-selectbox code="refund_request_reasons"
                                                     v-model="refundRequestForm.reason"
                                                     :validate-error="errors[0]"
                                ></parameter-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </div>
                <b-form-group>
                    <b-button variant="primary" @click="newRefundRequest">{{$t('save') | toUpperCase}}</b-button>
                </b-form-group>
            </ValidationObserver>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

// Services
import RefundRequestService from "../../../services/RefundRequestService";

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import isset from "isset-php";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        CurrencySelectbox,
        ParameterSelectbox,

        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('refund_request')
        }
    },
    data() {
        return {
            user: {},
            refundRequestForm: {
                name_surname: null,
                bank_name: null,
                branch_name: null,
                iban: 'TR',
                currency: null,
                reason: null
            },
        }
    },
    mounted() {
			this.isStudent();
    },
    methods: {
        async newRefundRequest() {
            const isValid = await this.$refs.refundRequestForm.validate();
            if (isValid) {
                this.$swal.fire({
                    text: this.$t('are_you_sure_want_save'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        const formData = {
                            'account_holder': this.refundRequestForm.name_surname,
                            'bank_name': this.refundRequestForm.bank_name,
                            'branch_name': this.refundRequestForm.branch_name,
                            'iban': this.refundRequestForm.iban.replaceAll('-', ''),
                            'currency': this.refundRequestForm.currency,
                            'reason': this.refundRequestForm.reason
                        }
                        RefundRequestService.create(formData)
                            .then(response => {
                                this.refundRequestForm = {
                                    'name_surname': null,
                                    'bank_name': null,
                                    'branch_name': null,
                                    'iban': null,
                                    'currency': null,
                                    'reason': null,
                                }
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.$router.push('/my-requests/2') /// send to 2. tab
                            })
                            .catch(error => {
                                this.showErrors(error,this.$refs.refundRequestForm)
                            });
                    }
                })
            }
        },
        isStudent(){
            this.user = this.$store.getters['auth/getUser'];
            // Öğrenci Değilse Yönlendir
            if (!isset(() => this.user.student.id)) {
                this.$router.push('/404');
            }
        }
    }
}
</script>
